

.listParent {
  text-align: center;
  height: 450px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.list {
  animation: pan 40s linear infinite;
  -moz-animation: pan 40s linear infinite;
  -webkit-animation: pan 40s linear infinite;
 
}
.word {
  font-size: 42px;
  font-style: italic;
  color: #2a7050;
  letter-spacing: 10px;
  margin: 25px;
  text-transform: lowercase;
}
@keyframes pan {
  0% {
    transform: translateY(450px);
    -moz-transform: translateY(450px);
    -webkit-transform: translateY(450px);
  }
  100% {
    transform: translateY(-100%);
    -moz-transform:translateY(-100%);
    -webkit-transform: translateY(-100%);
  }
}

  .list:hover  {
    animation-play-state: paused;
    -moz-animation-play-state: paused;
    -webkit-animation-play-state: paused;
  }
  
  
  
  @-moz-keyframes pan {
    0% {
      transform: translateY(350px);
      -moz-transform: translateY(350px);
      -webkit-transform: translateY(350px);
      }
      
    100% {
      transform: translateY(-100%);
      -moz-transform:translateY(-100%);
      -webkit-transform: translateY(-100%);
      }
  }

  @-webkit-keyframes pan {
    0% {
      transform: translateY(350px);
      -moz-transform: translateY(350px);
      -webkit-transform: translateY(350px);
      }
      
    100% {
      transform: translateY(-100%);
      -moz-transform:translateY(-100%);
      -webkit-transform: translateY(-100%);
      }
  }

  
