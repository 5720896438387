
.media-scroller {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 400px;
  overflow-x: auto;
  overflow-y: auto;
  overscroll-behavior-inline: contain;
}

.media-element {
  display: grid;
  grid-template-rows: min-content;
  margin: 10px;
  background-color: #ffffff;
  border-radius: 10px;
  transition: transform 450ms;
  border-style: groove;
  cursor: pointer;
  border: solid;
}

.media-element > img {
  inline-size: 100%;
  aspect-ratio: 16 / 9;
  object-fit: contain;
}

iframe {
  inline-size: 100%;
  aspect-ratio: 16 / 9;
  object-fit: cover;
}

.media-element:hover {
  transform: scale(1.07);
}

.image {
  width: 100%;
  border-radius: 10px;
  /* object-fit: cover; */
}

.snaps-inline {
  scroll-snap-type: inline mandatory;
}

.snaps-inline > * {
  scroll-snap-align: start;
}


