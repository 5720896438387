$seafoam: #589894;
$white: #ffffff;

$nav: 100;

$navHeight: 64px;

$trans: 0.3s ease;

* {
  box-sizing: border-box;
}

.inner {
  overflow: scroll;
  // padding-top: $navHeight;

  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  //gotta hide windows scrollbars

  &::-webkit-scrollbar {
    width: 0 !important;
  }

  .content {
    padding: ($navHeight - ($navHeight/1.5)) / 2;
    position: relative;
    margin-bottom: $navHeight/2;
  }
}

.message-wrapper {
  position: relative;
  overflow: hidden;

  width: 100%;
  margin: (($navHeight - ($navHeight/1.5)) / 2) 0;
  // padding: (($navHeight - ($navHeight/1.5)) / 2) 0;

  .circle-wrapper {
    height: $navHeight/1.5;
    width: $navHeight/1.5;
    border-radius: 50%;
  }

  .text-wrapper {
    padding: ($navHeight - ($navHeight/1.5)) / 2;
    min-height: $navHeight/1.5;
    width: 80%;
    margin: 0 ($navHeight - ($navHeight/1.5)) / 2;
    box-shadow: 0px 1px 0px 0px rgba(50, 50, 50, 0.3);
    border-radius: 2px;
    font-weight: 300;
    position: relative;
    /* word-break: break-all; */
    opacity: 1;

    &:before {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
    }
  }

  &.them {
    .circle-wrapper,
    .text-wrapper {
      background: $seafoam;
      float: left;
      color: $white;
      display: flex;
      //   justify-content: center;
      align-items: center;
    }

    .text-wrapper {
      &:before {
        border-width: 0 10px 10px 0;
        border-color: transparent $seafoam transparent transparent;
        position: absolute;
        top: 0;
        left: -9px;
      }
    }
  }

  &.them {
    .circle-wrapper {
      justify-content: center;
    }
  }
}
