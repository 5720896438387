.innerCard {
    flex-wrap: wrap;
    align-content: center;
}

.innerContent {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.sideBox {
    height: 35vh;
    overflow-y: scroll;
}

.mainMod {
    overflow-y: scroll;
}
